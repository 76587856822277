// assets
import {
    IconMan,
    IconPalette,
    IconShadow,
    IconFileInvoice,
    IconServicemark,
    IconBrandHipchat,
    IconMedicineSyrup,
    IconBuildingHospital,
    IconBuildingWarehouse,
    IconCalendarEvent
} from '@tabler/icons';

// constant
const icons = {
    IconMan,
    IconPalette,
    IconShadow,
    IconServicemark,
    IconBrandHipchat,
    IconMedicineSyrup,
    IconBuildingHospital,
    IconFileInvoice,
    IconBuildingWarehouse,
    IconCalendarEvent
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-field-agent',
            title: 'Field Agent',
            type: 'item',
            url: '/utils/util-field-agent',
            icon: icons.IconMan,
            breadcrumbs: false
        },
        {
            id: 'util-schedule',
            title: 'Schedule',
            type: 'item',
            url: '/utils/util-schedule',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-symptom',
            title: 'Symptoms',
            type: 'item',
            url: '/utils/util-symptom',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-Task',
            title: 'Task',
            type: 'item',
            url: '/utils/util-task',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-FirstAid',
            title: 'First Aid',
            type: 'item',
            url: '/utils/util-firstAid',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-field-agent-transaction',
            title: 'FA Transaction',
            type: 'item',
            url: '/utils/util-field-agent-transaction',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-medicine-transaction',
            title: 'Medicine Transaction',
            type: 'item',
            url: '/utils/util-medicine-transaction',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-schedule-transaction',
            title: 'Schedule Transaction',
            type: 'item',
            url: '/utils/util-schedule-transaction',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-farmer',
            title: 'Farmer',
            type: 'item',
            url: '/utils/util-farmer',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
        {
            id: 'util-doctor',
            title: 'Doctors',
            type: 'item',
            url: '/utils/util-doctor',
            icon: icons.IconBuildingHospital,
            breadcrumbs: false
        },
        {
            id: 'util-service',
            title: 'Service',
            type: 'item',
            url: '/utils/util-service',
            icon: icons.IconServicemark,
            breadcrumbs: false
        },
        {
            id: 'util-medical',
            title: 'Medical',
            type: 'item',
            url: '/utils/util-medical',
            icon: icons.IconMedicineSyrup,
            breadcrumbs: false
        },
        {
            id: 'util-center',
            title: 'Center',
            type: 'item',
            url: '/utils/util-center',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-offer',
            title: 'Offer',
            type: 'item',
            url: '/utils/util-offer',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-inventory',
            title: 'Inventory',
            type: 'item',
            url: '/utils/util-inventory',
            icon: icons.IconBuildingWarehouse,
            breadcrumbs: false
        },

        {
            id: 'util-invoices',
            title: 'Invoices',
            type: 'item',
            url: '/utils/util-invoices',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'util-livestock',
            title: 'Livestock',
            type: 'item',
            url: '/utils/util-livestock',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-livestock-type',
            title: 'Livestock Type',
            type: 'item',
            url: '/utils/util-livestock-type',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-livestock-membeship',
            title: 'Livestock Membership',
            type: 'item',
            url: '/utils/util-livestock-membeship',
            icon: icons.IconPalette,
            breadcrumbs: false
        }  
        // {
        //     id: 'util-chat',
        //     title: 'Chat',
        //     type: 'item',
        //     url: '/utils/util-chat',
        //     icon: icons.IconBrandHipchat,
        //     breadcrumbs: false
        // }
    ]
};
export const utilitiesFinance = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-field-agent',
            title: 'Field Agent',
            type: 'item',
            url: '/utils/util-field-agent',
            icon: icons.IconMan,
            breadcrumbs: false
        },
        {
            id: 'util-schedule',
            title: 'Schedule',
            type: 'item',
            url: '/utils/util-schedule',
            icon: icons.IconCalendarEvent,
            breadcrumbs: false
        },
        {
            id: 'util-medicine-transaction',
            title: 'Medicine Transaction',
            type: 'item',
            url: '/utils/util-medicine-transaction',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-FirstAid',
            title: 'First Aid',
            type: 'item',
            url: '/utils/util-firstAid',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-field-agent-transaction',
            title: 'FA Transaction',
            type: 'item',
            url: '/utils/util-field-agent-transaction',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-schedule-transaction',
            title: 'Schedule Transaction',
            type: 'item',
            url: '/utils/util-schedule-transaction',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-service',
            title: 'Service',
            type: 'item',
            url: '/utils/util-service',
            icon: icons.IconServicemark,
            breadcrumbs: false
        },
        {
            id: 'util-offer',
            title: 'Offer',
            type: 'item',
            url: '/utils/util-offer',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-invoices',
            title: 'Invoices',
            type: 'item',
            url: '/utils/util-invoices',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'util-livestock-membeship',
            title: 'Livestock Membership',
            type: 'item',
            url: '/utils/util-livestock-membeship',
            icon: icons.IconPalette,
            breadcrumbs: false
        }
        // {
        //     id: 'util-chat',
        //     title: 'Chat',
        //     type: 'item',
        //     url: '/utils/util-chat',
        //     icon: icons.IconBrandHipchat,
        //     breadcrumbs: false
        // }
    ]
};

export const utiitiesWithAdmin = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-admin',
            title: 'Admins',
            type: 'item',
            url: '/utils/util-admin',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
        {
            id: 'util-field-agent',
            title: 'Field Agent',
            type: 'item',
            url: '/utils/util-field-agent',
            icon: icons.IconMan,
            breadcrumbs: false
        },
        {
            id: 'util-symptom',
            title: 'Symptoms',
            type: 'item',
            url: '/utils/util-symptom',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-schedule',
            title: 'Schedule',
            type: 'item',
            url: '/utils/util-schedule',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-task',
            title: 'Task',
            type: 'item',
            url: '/utils/util-task',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-firstAid',
            title: 'First Aid',
            type: 'item',
            url: '/utils/util-firstAid',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-field-agent-transaction',
            title: 'FA Transaction',
            type: 'item',
            url: '/utils/util-field-agent-transaction',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-medicine-transaction',
            title: 'Medicine Transaction',
            type: 'item',
            url: '/utils/util-medicine-transaction',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-schedule-transaction',
            title: 'Schedule Transaction',
            type: 'item',
            url: '/utils/util-schedule-transaction',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-farmer',
            title: 'Farmer',
            type: 'item',
            url: '/utils/util-farmer',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
        {
            id: 'util-doctor',
            title: 'Doctors',
            type: 'item',
            url: '/utils/util-doctor',
            icon: icons.IconBuildingHospital,
            breadcrumbs: false
        },
        {
            id: 'util-service',
            title: 'Service',
            type: 'item',
            url: '/utils/util-service',
            icon: icons.IconServicemark,
            breadcrumbs: false
        },
        {
            id: 'util-medical',
            title: 'Medicine Stock',
            type: 'item',
            url: '/utils/util-medical',
            icon: icons.IconMedicineSyrup,
            breadcrumbs: false
        },
        {
            id: 'util-center',
            title: 'Center',
            type: 'item',
            url: '/utils/util-center',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-offer',
            title: 'Offer',
            type: 'item',
            url: '/utils/util-offer',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-inventory',
            title: 'Inventory',
            type: 'item',
            url: '/utils/util-inventory',
            icon: icons.IconBuildingWarehouse,
            breadcrumbs: false
        },
        {
            id: 'util-invoices',
            title: 'Invoices',
            type: 'item',
            url: '/utils/util-invoices',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'util-livestock',
            title: 'Livestock',
            type: 'item',
            url: '/utils/util-livestock',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-livestock-type',
            title: 'Livestock Type',
            type: 'item',
            url: '/utils/util-livestock-type',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-livestock-membeship',
            title: 'Livestock Membership',
            type: 'item',
            url: '/utils/util-livestock-membeship',
            icon: icons.IconPalette,
            breadcrumbs: false
        }
    ]
};

export default utilities;
